<template>
  <CRow>
    <CCol col="12" xl="6">
      <ul class="nav nav-tabs" role="tablist">
        <!-- tab Items  -->
        <li class="nav-item navbar-custom" v-for="(site, index) in siteTables" v-if='site.data'>
          <a tab class="nav-link" 
            :class="{active:index==active_site_index}" 
            data-toggle="tab" 
            :id="'#site' + index" 
            :href="'#site_tab' + index" 
            @click="onClickTab(index)" 
            role="tab">{{site.title}}
              <a v-if="site.device_count!=0">({{site.device_count}}대)</a>
          </a>
        </li>
      </ul>


      <!-- 선택분사-->
        <div style="padding:2% 3% 0% 0%; text-ali">
          <form>
          <CRow style="justify-content:flex-end">
            <div style="padding-right: 10px;">
              <select class="form-control" v-model="group_cycle"> 
                <option value="0" disabled selected>분사주기</option>
                <option value="1">1분</option>
                <option value="3">3분</option>
                <option value="5">5분</option>
            </select>
            </div>
            <div style="padding-right: 10px;">
              <select class="form-control" v-model="group_repeat" > 
                <option value="0" disabled selected>반복횟수</option>
                <option value="1">1회</option>
                <option value="2">2회</option>
                <option value="3">3회</option>
            </select>
            </div>
            <div style="padding-right: 10px;">
              <CButton color="success" @click="getSelectedGuid()" > 선택 분사 </CButton>
            </div>
          </CRow>
          </form>
        </div> 

      <div class="tab-content">
        <div class="tab-pane" v-for="(site, index) in siteTables" :id="'site_tab' + index" :class='{active:index==active_site_index}'  role="tabpanel" >
          <div class="card-body" >
            <v-table :id="'dataTable'+index" :columns="site.columns" :data="site.data" :options="site.options" @on-check-all="onCheckAll" @on-uncheck-all="uncheckAll"
            @on-check="onCheckDevice"  @on-uncheck="uncheckDevice" @on-click-row="onClickRow"
            >
            </v-table>
          </div>
        </div>
          <ActivationDialog 
            ref = "activationDialog"
            :show.sync="activationDialog"/>
      </div>
    </CCol>
    <CCol col="12" xl="6">
      <GoogleMap ref="googleMap" style="height:100%;padding-top:1.0%;padding-bottom:1.0%;padding-reft:1.0%;padding-right:1.0%" />
    </CCol>
  </CRow>
</template>

<script>

import Device from '@/views/dashboard/Device'
import loopback from '@/services/loopback';
import utils from '@/services/utils';
import CronParser from 'cron-parser'
import EventBus from '@/services/EventBus'
import moment from 'moment';
import ActivationDialog from '@/views/fumax/ActivationDialog';


import {
  DEVICE_TABLE_OPTIOINS, FUMAX_DEVICE_TABLE_COLUMNS
} from "@/constants/deviceSettings";

export default {
  name: 'FumaxDevice',
  extends: Device,
  components: {
    ActivationDialog
  },
  props: {
    prefix: {
      type: String,
      default: 'fumax_'
    }
  },
  data () {
    return {
      active_site_index: 0,
      siteTables: [],
      dashboard: {},
      sensor_type: utils.getSensorTypes(),
      selected_site: null,
      selected_device: null,
      activationDialog: false,
      storage: "storages",
      deviceImages: [],
      group_selected: [],
      group_cycle: 0,
      group_repeat: 0,

    }
  },
  methods: {
    makeTable(site_guids) {
      if (site_guids.length === this.siteTables.length) {
        return;
      }
      this.siteTables = [];
      for (var i = 0; i < site_guids.length; i++) {
        var table = {
          options: DEVICE_TABLE_OPTIOINS,
          columns: FUMAX_DEVICE_TABLE_COLUMNS,
          title: '',
          data: []
        }
        _.find(table.columns, { field: 'site' }).formatter = this.siteFormatter;
        _.find(table.columns, { field: 'name' }).formatter = this.nameFormatter;
        // 추가, 수정: 제설제, 전압, 통신상태, 작동상태, 센서(온/습도), 제어
        _.find(table.columns, { field: 'sensor' }).formatter = this.temperatureFormatter;
        _.find(table.columns, { field: 'waterlevel' }).formatter = this.waterlevelStatusFormatter;
        _.find(table.columns, { field: 'deicing' }).formatter = this.deicingFormatter;
        _.find(table.columns, { field: 'battery' }).formatter = this.batteryStatusFormatter;
        _.find(table.columns, { field: 'connecting' }).formatter = this.connectingFormatter;
        _.find(table.columns, { field: 'activation' }).formatter = this.activationFormatter;

        this.siteTables.push(table);
      }
    },
    onCheckDevice(row, $element) {
        this.group_selected.push(row.guid)    
    },
    onCheckAll(row){
      if(this.group_selected.length > 0){
        this.group_selected = [];
      }      
      for(var i = 0 ; i < row.length; i++){
        this.group_selected.push(row[i].guid); 
      }
    },
    uncheckAll(){
      this.group_selected = [];
    },
    uncheckDevice(row, $element){ 
      for(var i = 0; i < this.group_selected.length; i++){
        if(this.group_selected[i] == row.guid){
          this.group_selected.splice(i, 1); 
          i--;
        }
      }
      this.selected_site= null;
      this.selected_device = null;      
      $('#sitesTable').bootstrapTable('uncheckAll');
    },
    getSelectedGuid(){
      // 선택 분사 버튼 클릭시
      var group_cycle_ = this.group_cycle;
      var group_repeat_ = this.group_repeat;
      var self = this;

      if(this.group_selected.length == 0){
        console.log('Execute::Empty Value ')
        return;
      }

      for(var i=0; i < this.group_selected.length; i++){
        var executeTimer = function(x, guids){
          setTimeout(() => {
              self.execute(guids, group_cycle_, group_repeat_)
          }, 1000 * x)
        }
        executeTimer(i+1, this.group_selected[i]);
      }
    },
    execute(selecte_guid, cycle_, repeat_){
      console.log('Execute::Salt Spray guids: ', selecte_guid)

      var data = {
        command: 'exec',
        device_guid: selecte_guid,
        params: [{
          cmd: 'exec',
          type: 'jet',
          data: {
            action: 1,
            cycle: cycle_,
            repeat: repeat_
          }
        }]
      }
      console.log('Execute::Salt Spray Data:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Execute::Salt Spray res: ', res)
        })
        .catch(err => {
          console.log('Execute::Salt Spray error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
    temperatureFormatter(value, row, index){
      var self = this
      var result = "";
      value.data = _.sortBy(value.data, ['type']);
      // console.log("this is value.data ", value.data);
      value.data.forEach(function(v) {
        if (!_.get(row, "empty", false)) {
          if (v.id === 0) v.id = 1;
          var description = _.find(self.sensor_type, {
            code: v.type,
            id: v.id
          });
          if (!description) return
          
          var desc = "";
          switch(v.type){
            case 1:
              // desc = v.value + description.unit;
              break;
            // case 12:
            //   if(v.id === 1) desc = '습도: ' + v.value + description.unit + ' / ';
            //   else if(v.id === 2) desc = ' 온도: ' + v.value + description.unit + ' ';
            //   break;
            case 6:
              if(v.id === 1) desc = ' 온도: ' + v.value + description.unit + ' / ';
              else if(v.id === 2) desc = '습도: ' + v.value + description.unit + ' ';
              break;
            default:
              result = "측정값 없음";
              break;
          }
          result += desc;
        }
        else
          result = "측정값 없음";
      })
      return "<a>" + result + "</a>";

    },    
    waterlevelStatusFormatter(value, row, index) {
      var result = '';
      var waterBtn = '';
      if (_.isUndefined(row.sensor)){
          console.log("isUndefined");
          return;
      }

      row.sensor.data.forEach(function(e){
        if(e.type === 19)
          result = e.value;
      })

      if(result == '')
        result = '2.08';

      if (result >= 2.0){ 
        waterBtn = 'info';
      }
      else if (result >= 1.0 ){ 
        waterBtn = 'warning'
      }
      else if (result >= 0.5 ){
        waterBtn = 'warning'          
      }
      else if (result >= 0.3 ){
        waterBtn = 'danger'
      }
      else{
        waterBtn = 'danger' 

      }
      return '<button style="margin:0;cursor:default" class="btn btn-' + waterBtn + ' btn-sm">' + result+ ' (m) </button>';
      
    },
    deicingFormatter(value, row, index) {
      return '<button class="btn btn-success btn-sm" >75%</button>';
    },
    batteryStatusFormatter(value, row, index) {
      var result = '';
      var batteryBtn = '';
      var thresholds = utils.getThresholds();
      var level0= _.find(thresholds, {sensor_type : 1, level : 0 }).min;
      var level1= _.find(thresholds, {sensor_type : 1, level : 1 }).min;
      var level2= _.find(thresholds, {sensor_type : 1, level : 2 }).min;
      var level3= _.find(thresholds, {sensor_type : 1, level : 3 }).min;
      if (_.isUndefined(row.sensor)){
          console.log("isUndefined");
          return;
      }

      row.sensor.data.forEach(function(e){
        if(e.type === 1)
          result = e.value;
      })

      if (result >= level0){ 
        batteryBtn = 'success';
      }
      else if (result >= level1 ){ 
        batteryBtn = 'warning'
      }
      else if (result >= level2 ){
        batteryBtn = 'warning'          
      }
      else if (result >= level3 ){
        batteryBtn = 'danger'
      }
      else{
        batteryBtn = 'danger' 

      }
      return '<button style="margin:0;cursor:default" class="btn btn-' + batteryBtn + ' btn-sm">' + result+ ' (V) </button>';
      
    },

    connectingFormatter(value, row, index){
      if (_.isUndefined(row.sensor)){
          console.log("isUndefined");
          return;
      }
      if (_.get(row, "empty", false))
        return "-";
      var date_string = moment(value).format('YYYY-MM-DD HH:mm:ss');
      if (row.status !== 0 || new Date(value).getTime() < new Date(row.sensor_time).getTime())
        return '<button style="cursor:default" class="btn btn-success btn-sm" >' + "수  신"  +'</button>';
      else
        return '<button style="cursor:default" class="btn btn-danger btn-sm" >' + "미수신"  +'</button>';
    },

    activationFormatter(value, row, index){
      // return '<button class ="btn btn-success btn-sm"> 작동중 </button>'
      var self = this;
      var result = '';
      var element = '';
      var is_activation = 0;
      row.sensor.data.forEach(function(e){
        if(e.type === 9)
          is_activation = e.value;
      })
      if(is_activation == 1){
        element = '<button id="disabledBtn_{0}" class="btn btn-info btn-sm" :disabled>분사중</button>';
      }
      else{
        element = '<button id="activationBtn_{0}" class="btn btn-secondary btn-sm" :disabled>제어대기</button>';
      }

      result = element.format(row.guid);
      
      return result;
    },
    getDeviceImages(row) {
      
      var self = this;
      var img;
      this.deviceImages = [];
      var sensorData = {
        battery: '',
        humidity : '', 
        temp : '', 
        waterlevel:'0.082'
      };

      row.sensor.data.forEach(function(e){
        if(e.type === 1) {
          sensorData.battery = e.value;
        }
        if(e.type === 12){
          if(e.id === 1) sensorData.humidity = e.value;
          if(e.id === 2) sensorData.temp = e.value;
        }
        else if(e.type === 6){
          if(e.id === 2) sensorData.humidity = e.value;
          if(e.id === 1) sensorData.temp = e.value;
        }
        else if(e.type === 19){
          if(e.id === 1) sensorData.waterlevel = e.value;
        }
        else if(e.type === 9){
          if(e.id === 1) sensorData.activation = e.value;
        }
      })
      var selected_device_guid = row.guid; 
      this.storage = 'flowPhotos'

      var download_url = '{0}/{1}/{2}/download'.format(loopback.defaults.baseURL, this.storage, selected_device_guid)
      // download_url = download_url.replace('storages', 'flowPhotos');
      /*****************************************************/
      var now =  new Date();
      var month = new Date(now.setMonth(now.getMonth() - 1));	

      var to = moment(now).format('YYYY-MM-DD HH:mm:ss');
      var from = moment(month).format('YYYY-MM-DD HH:mm:ss');
      /*****************************************************/
      
      var filter = {
        where: {
          and: [{
              device_guid: selected_device_guid
            },
            // {
            //     created_at: {
            //       "gte": from
            //     }
            //   },
          ]
        },
        order: 'created_at desc',
        limit: 5,
      }

      self.$store.dispatch('rest/find', {model:'photos',filter:filter})
        .then(function(response) {
          var pushImage = function(info) {
            self.deviceImages.push({
                url: download_url + '/' + info.file_name, 
                created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
              })
          }
          for (var i = 0; i < response.length; i++) {
            var info = response[i];
            if (_.isUndefined(info.storage)) continue;
            pushImage(info);
          }

          if(response.length == 0){
              download_url = 'http://www.fumax.co.kr/default/img/popup.jpg';
              self.deviceImages.push({
                url: download_url
            })
            console.log("no image file")
          }
            img = self.deviceImages
            self.$refs.activationDialog.show(selected_device_guid, row.name, img, sensorData);
      })
    },  
    onClickRow(field, value, row) {
      this.$refs.googleMap.setCenter(field);
      if(row ==='activation'){
        this.getDeviceImages(field);
      }
      this.$emit('click', field, value, row);
    },
    getDeviceList(index, limit, skip) {
      var self = this;
      if (typeof limit === 'undefined') limit = 50
      if (typeof skip === 'undefined') skip = 0

      var default_site_guid = _.get(this.user, "default_site_guid");
      var active_site_index = _.get(this.user, "active_site_index", 0)
      var data = {
        limit: limit,
        skip: skip,
        site_guid: _.isEmpty(this.user.active_site_guids) ? this.user.site_guids : this.user.active_site_guids,
        active_site_index: active_site_index
      }

      // this.makeTable(data.site_guid)
      return loopback
        .method('sites', 'getDeviceList', data)
        .then(res => {
          // console.log('Device::getDeviceList: ', JSON.stringify(res,null,2))
          self.makeTable(_.map(res, 'site_guid'))

          self.user.active_site_guids = []
          res.forEach(function(d, i) {
            var rows = [];
            d.data.forEach(function(e) {
              var interval = CronParser.parseExpression(e.upload_cron)
              var data = {
                site: d.Site.name,
                site_guid: d.Site.guid,
                site_index: i,
                center_device_guid: d.Site.center_device_guid,
                latitude: e.latitude,
                longitude: e.longitude,
                name: e.name,
                service_type: e.service_type,
                address: e.address,
                sensor: e.sensor,
                standard: e.standard,
                empty: e.sensor.empty,
                sensor_time: e.sensor.created_at,
                connecting: new Date(interval.prev()),
                guid: e.guid,
                threshold: e.threshold,
                activation: "",
                images: e.images,
                status: e.status                
              }
              rows.push(data)
            });
            var active_site_index = _.get(self.user, "active_site_index", -1);
            if (active_site_index != -1 && self.active_site_index != active_site_index) {
              if (active_site_index < res.length)
                self.active_site_index = active_site_index;
            } else {
              if (default_site_guid == d.site_guid) {
                self.active_site_index = i;
              }
            }

            if (_.indexOf(self.user.active_site_guids, d.site_guid) === -1) {
              self.user.active_site_guids.push(d.site_guid);
            }
            self.siteTables[i].title = d.Site.name;
            self.siteTables[i].data = rows;
            self.siteTables[i].device_count = rows.length;
          });
          var siteTable = self.siteTables[self.active_site_index]
          self.user.active_site_name = siteTable.title;
          utils.setStorage("userInfo", self.userInfo);

          if (!_.isUndefined(self.$refs.googleMap))
            self.$refs.googleMap.update(siteTable);

          // notify to parent : change
          self.$emit('change', siteTable)
          return Promise.resolve(siteTable)
        })
    } // getDeviceList
  }
}
</script>