<template>
  <div>
    <CCard style="height: 155px">
      <Summation ref="summationView" />
    </CCard>

    <CCard>
      <Device ref="deviceView" @change="onChangedSite" prefix="fumax_"/>
    </CCard>

    <Event ref="eventView" style="display:none;" @update="updateSummation" />

    <!--<EventDialog ref="eventDialog" @click="onClickEvent"/>-->
  </div>
</template>

<script>

import Dashboard from '@/views/dashboard/Dashboard'
import Summation from "@/views/fumax/Summation";
import Device from "@/views/fumax/Device";
import { DATE } from '@/constants/constant'
import moment from 'moment';

export default {
  name: "FumaxBoard",
  extends: Dashboard,
  components: {
    Summation,
    Device,
  },
  data() {
    return {
      selected_site_devices: [],      
      dates: [],
      search: {},
    };
  },
  methods: {
    updateSummation(data) {
      var where = {
        and: [
          {
            status: 0,
            //service_type: 'fumax',
          },
        ],
      };
      if (this.is_admin === false) {
        where.and.push({
          site_guid: {
            inq: this.user.site_guids,
          }
        })
      }
      this.$store
        .dispatch("rest/count", { model: "devices", where: where })
        .then((res) => {
            var self = this;
            var query = {
              date_type: DATE.TYPE.DAILY,
              site_guid: self.search.site_guid,
              device_guid: self.search.device_guid,
              from: moment(this.dates[0]).format('YYYY-MM-DD'),
              to: moment(this.dates[1]).format('YYYY-MM-DD'),
              limit: self.search.limit,
              skip: self.search.skip,
            }
            this.$store.dispatch('rest/method', {model:'missing_stats', method:'getStat', data:query})
              .then(missing => {
                var missing_count = 0;
                var push_data=[];
                
                for(var k=0;k<self.user.site_guids.length;k++){
                  for(var i=0; i < missing.data.length; i ++){
                    for(var j=0;j<missing.data[i].missings.length;j++){
                      if(self.user.site_guids[k] == missing.data[i].missings[j].site_guid){
                        push_data.push(missing.data[i].missings[j])
                      }
                    }
                  }
                }

                var missing_ = push_data;
                var result = missing_.filter((item1, idx1) =>{
                  return missing_.findIndex((item2, idx2)=>{
                    return item1.device_guid == item2.device_guid;
                  }) == idx1;
                });
                console.log("=================> befor count: ", missing_.length);
                console.log("-----------------> after count: ", result.length);                        
                missing_count =  result.length;
                //console.log(missing);

                this.$refs.summationView.update(res.count, data, missing_count);
              })
              .catch((err) => {
                console.log('queryMissing error: ', err.toString())
                this.$refs.notifyDialog.show(err.toString())
              })          
        })
        .then(()=>{
          var eventTable = this.$refs.eventView.getTable();
          eventTable.data.forEach((event) => {
            this.$refs.deviceView.setMarkerImage(event);
          });
        });
    },
  },
};
</script>