<template>
  <CRow>
    <CCol col="12" xl="12" v-if="$is_mobile">
      <table id="statTable" class="table table-striped table-bordered table-hover" data-toggle="table">
        <thead>
          <tr>
            <th colspan="4" class="text-center">{{headerTitles[0]}}</th>
          </tr>
          <tr>
            <th class="text-center" v-for="(name, index) in subTitles" v-if="index>=0&&index<3">{{name}}</th>
          </tr>
        </thead>
        <tbody>
          <td class="text-center" v-for="(item, index) in data" v-if="index>=0&&index<3">
            <div class="containerBox">
              <img class="img-responsive" :src="findImage(item.key)" width="30pt" height="30pt"/>
              <div class="text-box"><h5 class="dataNumber">{{item.val}}</h5></div>
            </div>
          </td>
        </tbody>
      </table>
    </CCol>

    <CCol col="12" xl="12" v-else>
      <table id="statTable" class="table table-striped table-bordered table-hover" data-toggle="table">
        <thead>
          <tr>
            <th colspan="3" class="text-center" v-for="name in headerTitles">{{name}}</th>
          </tr>
          <tr>
            <th class="text-center" v-for="name in subTitles">{{name}}</th>
          </tr>
        </thead>
        <tbody>
          <td class="text-center" v-for="item in data"  @click="onClick(item)">
            <div class="containerBox">
              <img class="img-responsive" :src="findImage(item.key)" width="30pt" height="30pt"/>
              <div class="text-box"><h5 class="dataNumber">{{item.val}}</h5></div>
            </div>
          </td>
        </tbody>
      </table>
    </CCol>
  </CRow>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm';
import utils from '@/services/utils';
import { IMAGES } from "@/constants/constant"

import {
  SITE_TABLE_OPTIOINS, SITE_TABLE_COLUMS,
  DEVICE_TABLE_OPTIOINS, DEVICE_TABLE_COLUMS
} from "@/constants/siteSettings";

export default {
  name: 'Summation',
  components: {
  },
  created: function() {

  },
  data () {
    return {
      headerTitles: [
        '배터리',
        '데이터',
        //'작동상태',
      ],
      subTitles: [
        '좋음', '보통',  '경고',
        '전체', '수신',  '미수신',
        //'대기', '준비',  '분사',
      ],
      data: [
        { key: 'BG', val: 1 }, { key: 'BC', val: 0 }, { key: 'BW', val: 0 },
        { key: 'PG', val: 1 }, { key: 'PN', val: 0 }, { key: 'PW', val: 0 },
        //{ key: 'PG', val: 1 }, { key: 'PN', val: 0 }, { key: 'PW', val: 0 },
      ]
    }
  },
  computed: {
  },
  methods: {
    findImage(key) {
      return IMAGES[key].name
    },
    onClick(item) {
      this.$emit('click', item)
    },
    update(count, events, missing_count) {
      this.data.forEach(elem => elem.val = 0);
      events.forEach(row => {
        var key = 'BG';
        switch (row.sensor_type) {
          case 1:
            key = row.level == 1 ? 'BC' : row.level == 2 ? 'BW' : 'BW';
            break;        
        }
        _.find(this.data, { key: key }).val += 1;
      })
      _.find(this.data, { key : 'BG' }).val = count - (_.find(this.data, {key:'BC'}).val + _.find(this.data, {key:'BW'}).val)
      _.find(this.data, { key: 'PG'}).val = count;
      _.find(this.data, { key: 'PN'}).val = count - missing_count;
      _.find(this.data, { key: 'PW'}).val = missing_count;
    }
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
.containerBox {
  position: relative;
  display: inline-block;
}

.text-box {
  position: absolute;
  height: 80%;
  color: white;
  text-align: center;
  width: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 30px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: 30px;
  margin: auto;
  padding: auto;
}

.dataNumber {
  margin-top: auto;
}

</style>