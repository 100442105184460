<template>
  <KModal
    :show.sync="activationDialog"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Image Viewer"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">{{ title }}</h6>
    </template>

    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12 col-lg-12" style="text-align: center">
          <div id="device-img" class="carousel slide carousel-fade" data-ride="carousel" data-interval="700">
            <!--<ol class="carousel-indicators">
              <li
                data-target="#device-img"
                :data-slide-to="index"
                v-for="(img, index) in deviceImages"
                :key="index"
                :class="{ active: index == 0 }"
              ></li>
            </ol>-->
            <div class="carousel-inner">
              <div
                class="carousel-item"
                v-for="(img, index) in deviceImages"
                :key="index"
                :class="{ active: index == 0 }"
              >
                <img
                  class="d-block w-100"
                  :src="img.url"
                  :style="image_style"
                  alt=""
                />
                <div class="carousel-caption" @click="takePhoto()" id="refresh_pic" style="background: rgba(0, 0, 0, 0.7);width: 100%; height: 100%; left:0%; bottom: 0%; cursor:pointer;">
                  <p style="margin-top: 27%;">
                    사진 새로고침 
                  </p>

                </div>
                <div class="carousel-caption">
                  <p style="background: rgba(0, 0, 0, 0.5)">
                    {{ img.created_at }}
                  </p>
                </div>
              </div>
            </div>
            <a
              style="z-index:100"
              class="carousel-control-prev"
              href="#device-img"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              style="z-index:100"
              class="carousel-control-next"
              href="#device-img"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>

      <div class="search-margin"></div>

      <div class="row">
        <div class="col-sm-6 col-lg-6">
          <label>디바이스명 <strong class="text-danger">*</strong></label>
          <input
            type="text"
            class="form-control"
            style="background-color: white"
            v-model="title"
            :required="true"
            :disabled="true"
          />
        </div>
        <div class="col-sm-6 col-lg-6">
          <label>상태 </label>
          <CProgress class="mb-3" :style="bar_style" >
            <CProgressBar v-if="is_activation" color="info" 
            variant="striped" animated :value="100">분사중</CProgressBar>
            <CProgressBar v-else color="secondary" 
            variant="striped" animated :value="100">분사대기중</CProgressBar>
          </CProgress>
        </div>
      </div>

      <div class="search-margin"></div>

      <form name="deviceForm">
        <div class="row">
          <div class="col-sm-4 col-lg-4">
            <label><strong class="text-danger">분사 주기</strong> </label>
            <select
              id="upload_cron"
              placeholder="분사 주기"
              class="form-control"
              v-model="cronData.cycle"
            >
            
            <option value="" disabled hidden> 분사 주기 </option>

              <option
                v-for="(i, index) in cronStrings"
                :value="i.value"
                :key="index"
              >
                {{ i.name }}
              </option>
            </select>
          </div>
          <div class="col-sm-4 col-lg-4">
            <label><strong class="text-danger">반복 횟수</strong> </label>
            <select
              id="upload_cron"
              placeholder="반복횟수"
              class="form-control"
              v-model="cronData.repeat"
            >
            <option value="" disabled hidden> 반복 횟수 </option>
              <option
                v-for="(j, index) in repeats"
                :value="j.value"
                :key="index"
              >
                {{ j.name }}
              </option>
            </select>
          </div>
          <div class="col-sm-4 col-lg-4">
            <label style="color: white"> 분사</label>
            <br />
            <div v-if="is_activation">
              <CButton class="form-control" color="secondary">
                분사중
              </CButton>
            </div>
            <div v-else>
              <CButton class="form-control" color="info" @click="confirm_()">
                분사
              </CButton>
            </div>
          </div>
        </div>
      </form>

      <div class="search-margin"></div>

      <div class="row">
        <div class="col-sm-3 col-lg-3">
          <label>전압(V)</label>
          <CProgress class="mb-3" :style="bar_style" >
            <CProgressBar color="success" 
            variant="striped" animated :value="(sensorData.battery)*18">{{ sensorData.battery }}V</CProgressBar>
          </CProgress>
        </div>
        <div class="col-sm-3 col-lg-3">
          <label>온도(°C)</label>
          <CProgress class="mb-3" :style="bar_style" >
            <CProgressBar color="warning" 
            variant="striped"  :value="100">{{ sensorData.temp }}°C</CProgressBar>
          </CProgress>
        </div>
        <div class="col-sm-3 col-lg-3">
          <label>습도(%)</label>
          <CProgress class="mb-3" :style="bar_style" >
            <CProgressBar color="primary" 
            variant="striped"  :value="100">{{ sensorData.humidity }}%</CProgressBar>
          </CProgress>
        </div>
        <div class="col-sm-3 col-lg-3">
          <label>수위(m)</label>
          <CProgress class="mb-3" :style="bar_style" >
            <CProgressBar color="info" 
            variant="striped"  :value="100">{{ sensorData.waterlevel }}m</CProgressBar>
          </CProgress>
        </div>
      </div>
    </div>

  <Confirm
      ref="confirmDialog"
      title="확인"
      @hide="hideModal"
      color="warning"
  />
    <template #footer-wrapper>
      <!-- empty footer -->
      <div></div>
    </template>
  </KModal>
  
</template>

<script>
import loopback from "@/services/loopback";

export default {
  name: "ActivationDialog",
  data() {
    return {
      activationDialog: false,
      title: "사진",
      which: "create",
      device_guid: "",
      deviceImages: [],
      sensorData: {
        battery: "",
        humidity: "",
        temp: "",
        waterlevel:""
      },
      is_activation : false,
      isEditMode: false,
      cronData: {
        cycle: "",
        repeat: "",
      },
      cronStrings: [
        {
          name: "1분",
          value: 1,
        },
        {
          name: "3분",
          value: 3,
        },
        {
          name: "5분",
          value: 5,
        },
      ],
      repeats: [
        {
          name: "1회",
          value: 1,
        },
        {
          name: "2회",
          value: 2,
        },
        {
          name: "3회",
          value: 3,
        },
      ],
    };
  },

  methods: {
    confirm_(){
      var message = "["+this.title  + "] 을(를) 분사제어 하시겠습니까?"
      this.$refs.confirmDialog.show(message, '메시지', undefined, 'execute');   
    },
    hideModal(event) {
        console.log(event);
      if (event.which === 'execute') {
        if(event.data === 'confirm'){
          this.execute();
          this.activationDialog = false;
        }        
        return
      } 
    },
    register() {
      this.which === "create" ? this.createUser() : this.updateUser();
      this.activationDialog = false;
    },
    cancel() {
      this.activationDialog = false;
    },
    show(guid, title, deviceImages, sensorData) {
      this.device_guid = guid;
      this.title = title;
      this.deviceImages = deviceImages;
      this.sensorData = sensorData;
      this.is_activation = sensorData.activation == 1 ? true:false;
      this.activationDialog = true;
    },
    execute() {
      // console.log("실행");
      // console.log(this.cronData.cycle);
      // console.log(this.cronData.repeat);

      if(this.cronData.cycle == "" ||  this.repeat == ""){
        console.log('Execute::Empty Value ')
        return;
      }

      console.log('Execute::Salt Spray guid: ', this.device_guid)

      var data = {
        command: 'exec',
        device_guid: this.device_guid,
        params: [{
          cmd: 'exec',
          type: 'jet',
          data: {
            action: 1,
            cycle: this.cronData.cycle,
            repeat: this.cronData.repeat
          }
        }]
      }
      console.log('Execute::Salt Spray Data:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Execute::Salt Spray res: ', res)
        })
        .catch(err => {
          console.log('Execute::Salt Spray error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
    takePhoto(){
      console.log('Camera::takePicture dicrection: ', this.device_guid)

      var data = {
        command: 'exec',
        device_guid: this.device_guid,
        params: [{
          cmd: 'exec',
          type: 'pi',
          data: {
            cmd: 'pi',
            type: 'le',
            action: 1
          }
        }]
      }
      // console.log('Camera::takePicture params:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Camera::takePicture res: ', res)
        })
        .catch(err => {
          console.log('Camera::takePicture error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },

  },
  created: function () {
    var image_height = 300;

    this.image_style = {
      width: "100%",
      "min-height": image_height + "px",
      "max-height": image_height + "px",
    };

    this.bar_style ={
      "height" : "calc(1.5em + 0.75rem)",
      "font-size" : "1rem"
    }
  },
};
</script>

<style scoped>
#refresh_pic{
  opacity:0;
  transition:500ms ease-in-out;
}
.carousel-item:hover #refresh_pic{
  opacity:1;
}
</style>